<template>
    <div>
        <b-container fluid class="search-bar">
            <b-container>
                <section class="search-bar__content">
                    <h2 class="main-title main-title--white">Join the Creative Community!</h2>
                    <p class="main-subtitle main-subtitle--white">Unique social media marketing platform for digital world</p>
                    <div class="search-bar__box">
                        <b-card no-body>
                            <b-tabs card no-fade>
                                <b-tab title="Agencies" active>
                                    <b-row>
                                        <b-col cols="12" md="9" xl="10">
                                            <input v-on:keyup.enter="onEnterAgency" v-model="searchAgency" type="text" class="search-bar__input" placeholder="Search by company name or city or country" />
                                        </b-col>
                                        <b-col cols="12" md="3" xl="2">
                                            <router-link v-if="searchAgency" :to="{ name: 'agencies', query: { agencyParam: searchAgency } }" class="main-button main-button--search"><simple-line-icons
                                                icon="magnifier"
                                                size="small"
                                                color="#000" /> Search</router-link>
                                                <button v-else class="main-button main-button--search"><simple-line-icons
                                                icon="magnifier"
                                                size="small"
                                                color="#000" /> Search</button>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Events">
                                    <b-row>
                                        <b-col cols="12" md="9" xl="10">
                                            <input v-on:keyup.enter="onEnterEvent" v-model="searchEvent" type="text" class="search-bar__input" placeholder="Search by event name or city or country" />
                                        </b-col>
                                        <b-col cols="12" md="3" xl="2">
                                            <router-link v-if="searchEvent" :to="{ name: 'events', query: { eventParam: searchEvent } }" class="main-button main-button--search"><simple-line-icons
                                                icon="magnifier"
                                                size="small"
                                                color="#000" /> Search</router-link>
                                                <button v-else class="main-button main-button--search"><simple-line-icons
                                                icon="magnifier"
                                                size="small"
                                                color="#000" /> Search</button>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Jobs">
                                    <b-row>
                                        <b-col cols="12" md="9" xl="10">
                                            <input v-on:keyup.enter="onEnterJob" v-model="searchJob" type="text" class="search-bar__input" placeholder="Search by job title or city or country" />
                                        </b-col>
                                        <b-col cols="12" md="3" xl="2">
                                            <router-link v-if="searchJob" :to="{ name: 'jobs', query: { jobParam: searchJob } }" class="main-button main-button--search"><simple-line-icons
                                                icon="magnifier"
                                                size="small"
                                                color="#000" /> Search</router-link>
                                                <button v-else class="main-button main-button--search"><simple-line-icons
                                                icon="magnifier"
                                                size="small"
                                                color="#000" /> Search</button>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </section>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import router from '../router/router'
export default {
  components: {
    SimpleLineIcons
  },
  data: function () {
    return {
      searchAgency: '',
      searchEvent: '',
      searchJob: ''
    }
  },
  methods: {
    onEnterAgency () {
      router.push({ name: 'agencies', query: { agencyParam: this.searchAgency } })
    },
    onEnterEvent () {
      router.push({ name: 'events', query: { eventParam: this.searchEvent } })
    },
    onEnterJob () {
      router.push({ name: 'jobs', query: { jobParam: this.searchJob } })
    }
  }
}
</script>
